/**=====================
     75. knowledgebase CSS start
==========================**/
.search-knowledge {
  input {
    background-color: $white;
    padding-left: 15px;
  }
  .form-group {
    &:before,
    &:after {
      display: none;
    }
  }
}
.search-not-found {
  align-items: center;
  justify-content: center;
  height: 631px;
  display: flex;
  .second-search {
    width: 200px;
  }
  &.knowledge-search-height {
    height: auto;
  }
  &.chat-search {
    height: 500px;
  }
} 
.browse{
  .browse-articles{
    &.browse-bottom{
      margin-top: 30px;
    }
    span{
      vertical-align: middle;
      svg{
        width: 14px;
        height: 14px;
        margin-right: 10px;
        vertical-align: text-top;
      }
    }
    h6{
      font-weight: 600;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $light;
      span{
        svg{
          width: 16px;
          height: 16px;
          vertical-align: text-top;
        }
      }
    }
    ul{
      li{
        padding: 10px;
        border-radius: 8px;
        a{
          color: $theme-body-font-color;
        }
        &:hover{
          background-color: $light;
        }
      }
    }
  }
  div {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      .browse-bottom {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 1366px){
  .browse{
    div[class^="col-"]{
      &:nth-child(3){
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .browse{
    div {
      &:nth-child(2) {
        .browse-bottom {
          margin-top: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px){
  .browse{
    div[class^="col-"]{
      &:nth-child(3) {
        margin-top: 20px;
      }
      &:nth-child(2) {
        .browse-bottom {
          margin-top: 20px;
        }
      }
    }
    .browse-articles{
      &.browse-bottom {
        margin-top: 20px;
      }
    }
  }
}
@media only screen and (max-width: 767px){
  .browse{
    div[class^="col-"]{
      &:nth-child(2) {
        margin-top: 20px;
        .browse-bottom {
          margin-top: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px){
  .browse{
    div[class^="col-"]{
      &:nth-child(2),
      &:nth-child(3){
        margin-top: 15px;
      }
    }
    .browse-articles{
      &.browse-bottom {
        margin-top: 15px;
      }
      ul {
        li {
          a {
            span {
              &:nth-child(2) {
                width: 277px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .search-knowledge {
    &.pull-right {
      float: none;
    }
  }
}
@media only screen and (max-width: 360px){
  .browse{
    .browse-articles{
      ul{
        li{
          a{
            span{
              &:nth-child(2){
                width: 158px;
              }
            }
          }
        }
      }
    }
  }
}
/**=====================
     75. knowledgebase CSS end
==========================**/
