/* You can add global styles to this file, and also import other style files */
@import url("https://unpkg.com/leaflet@1.7.1/dist/leaflet.css");
@import url("https://api.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.css");

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';


.marker {
    width: 50px;
    height: 50px;
    //background-color: red;
    //background-image: url("/src/assets/images/motorbike.png");
    background-image: url("https://colp06.expertosip.com.co:98/images/delivery.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

/**
 * Checkboxes
**/
.checkbox {
    position: relative;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    padding: 0;
  
    input[type='checkbox'] {
      position: relative;
      margin: 0 1rem 0 0;
      cursor: pointer;
      outline: none;
  
      &:before {
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        content: '';
        position: absolute;
        left: 0;
        z-index: 1;
        width: 1rem;
        height: 1rem;
        border: 2px solid #cf1717;
      }
  
      &:checked:before {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: 0.5rem;
        border-color: #009688;
        border-top-style: none;
        border-right-style: none;
      }
  
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1rem;
        height: 1rem;
        background: #fff;
        cursor: pointer;
      }
    }
  }



.inicial{
    background: #1ea6ec;
    border-radius: 50px;
    color: white;
    text-align: center;
    font-weight: bold;
}
.laboratorio{
    background: #1e7e34;
    border-radius: 50px;
    color: white;
    text-align: center;
}
.pp{
    background: #FEE100;
    border-radius: 50px;
    color: black;
    text-align: center;
}
.final{
    background: #FF60A8;
    border-radius: 50px;
    color: white;
    text-align: center;
}
.factu{
    background: rebeccapurple;
    border-radius: 50px;
    color: white;
    text-align: center;
}
.anulado{
    background: #ff5370;
    border-radius: 50px;
    color: white;
    text-align: center;
}

.pendiente{
    background: orange;
    border-radius: 50px;
    color: white;
    text-align: center;
}





.produccion {
    background: aqua;
    border-radius: 50px;
    color: black;
}

.plancha {
    background: #1ea6ec;
    border-radius: 50px;
    color: white;
}

.impreso {
    background: #4466f2;
    border-radius: 50px;
    color: white;
}

.finalizado {
    background: #ff5370;
    border-radius: 50px;
    color: white;
}

.despachado {
    background: #1e7e34;
    border-radius: 50px;
    color: white;
}

.facturado {
    background: rebeccapurple;
    border-radius: 50px;
    color: white;
}

.entregado {
    background: #FFFF00;
    border-radius: 50px;
    color: black;
}

.btnentregado {
    background: #FFFF00;
    border-radius: 60px;
    border-color: #FFFF00;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: black;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
}

.btnanulado {
    background: rebeccapurple;
    border-radius: 60px;
    border-color:  rebeccapurple;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    letter-spacing: 0.2px;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    color: white;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
}

.naranja{
    font-weight: bold;
    color: orange;
}

.verde{
    font-weight: bold;
    color: green;
}

.rojo{
    font-weight: bold;
    color: red;
}

.observacion{
    background: #ff0000a6;
    border-radius: 50px;
    font-weight: bold;
    color: white;
}


.spinnerBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 50vh;
    padding-bottom: 50vh;
    vertical-align: middle;
    z-index: 900;
}

.spinnerContent {
    text-align: center;
    position: relative;
}

.spinnerItem {                                               
    margin: 0 auto 0;
    width: 70px;
    text-align: center;
}

    .spinnerItem > div {
        width: 18px;
        height: 18px;
        background-color: #333;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinnerItem .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinnerItem .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.MensajeDeEspera{
    font-size: large;
    font-weight: bold;
    color: #4466f2;
}

.btnenviaracuse {
    background: #4466f2 !important ;
    border-radius: 60px;
    border-color: #4466f2 !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}

.btnenviadoacuse {
    background: green !important ;
    border-radius: 60px;
    border-color: green !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}


.btnnoenviadoacuse {
    background: red !important ;
    border-radius: 60px;
    border-color: red !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}

.btnverdian {
    background:orange !important ;
    border-radius: 60px;
    border-color:orange !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}

.columnasngxdatatable{
    width: auto;
}

.btncambiarfecha {
    background:green !important ;
    border-radius: 60px;
    border-color:green !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}

.btncambiartercero {
    background: #1ea6ec !important ;
    border-radius: 60px;
    border-color:  #1ea6ec !important ;
    font-size: 12px;
    padding: 0.375rem 1.75rem;
    color: white;
    position: relative;
    flex: 1 1 auto;
    box-sizing: border-box;
    letter-spacing: 0.2px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    
}

.btncancelswal{
    background-color: #d33;
}

.btnconfirmaswal{
    background-color: #3085d6;
}

body{
    font-size: 12px  !important;    
}

