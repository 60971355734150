/**=====================
    30. Data-table CSS Start
==========================**/
.noscroll{
  .datatable-footer{
    .datatable-footer-inner{
      height: auto !important;
    }
  }
}
.selection-datatable {
  .table-responsive {
    ng2-smart-table-pager {
      margin-top: 0 !important;
    }
  }
}
.custom-datatable {
  input {
    padding:8px;
    margin-bottom: 30px;
    border: 1px solid $light-semi-gray;
    width:30%;
    color: $light-text;
    &::placeholder {
      color: $light-text;
    }
  }
  .datatable-icon-right {
    &:before {
      content: "\f054";
      font-family: $font-awesome;
      font-size: 10px;
    }
  }
  .datatable-icon-left {
    &:before {
      content: "\f053";
      font-family: $font-awesome;
      font-size: 10px;
    }
  }
  .datatable-icon-prev {
    &:before {
      content: "\f100";
      font-family: $font-awesome;
      font-size: 14px;
    }
  }
  .datatable-icon-skip {
    &:before {
      content: "\f101";
      font-family: $font-awesome;
      font-size: 14px;
    }
  }
  .ngx-datatable {
    .datatable-checkbox {
      margin-bottom: 0;
      input {
        width: auto;
        margin-bottom: 0;
      }
    }
    &.bootstrap {
      .datatable-header {
        border: 1px solid $light-semi-gray;
        border-bottom: none;
        .datatable-header-cell {
          border-bottom: none;
        }
      }
      .datatable-body  {
        border: 1px solid $light-semi-gray;
        border-top: none;
        margin-bottom: 6px;
        .datatable-selection {
          .datatable-scroll {
            .datatable-row-wrapper {
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }

        .datatable-body-row {
           input {
             margin-bottom: 0;
           }
          &.active {
            background-color: $primary-color;
          }
          &.datatable-row-even {
            background-color: #f9f9f9;
            &:hover {
              background-color: $light;
            }
            &.active {
              background-color: $primary-color;
            }
          }
          &.datatable-row-odd {
            &:hover {
              background-color: $light;
            }
          }
        }
      }
      .datatable-footer {
        background: $white;
        color: inherit;
        .datatable-pager {
          margin-right: 0;
          ul {
            li {
              margin: 0;
              &:not(.disabled).active {
                a {
                  background-color: $primary-color;
                  color: $white;
                }
              }
              &:not(.disabled):hover {
                a {
                  border: 1px solid $primary-color;
                  background-color: $transparent-color;
                  color: inherit;
                }
              }
            }
          }
          a {
            color: inherit;
            height: 38px;
            min-width: 38px;
            line-height: 38px;
          }
        }
      }
    }
    .datatable-body-row, .datatable-row-center, .datatable-header-inner {
      display: -webkit-box !important;
    }
    .datatable-footer {
      .datatable-pager {
        .pager {
          border: 1px solid $light-semi-gray;
          border-radius: 4px;
        }
      }
    }
  }
}
div{
  &.dt-button-background{
    display: none !important;
  }
}
.dataTables_wrapper{
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 1px solid $light-gray;
    }
  }
  .dt-buttons{
    .btn{
      border-radius: 0.25rem !important;
    }
  }
  padding: 0;
  .btn-group{
    button{
      margin-right: 5px;
    }
  }
  button{
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: $white;
  }
  width: 100%;
  &+.dataTables_wrapper{
    margin-top: 20px;
  }
  .dataTables_paginate{
    margin-left: 15px !important;
    border: 1px solid $light-color;
    border-radius: 0.25rem;
    padding-top: 0;
    .paginate_button{
      margin: 0;
      &.current,&:active{
        background: $primary-color;
        color: $white !important;
        box-shadow: none;
      }
      &:hover{
        border:1px solid $primary-color;
        color: $dark-color !important;
        background: transparent !important;
      }
    }
  }
  .dataTables_length{
    margin-bottom: 30px;
    select{
      border-color: $light-color;
      color: $dark-color;
      padding: 0 10px;
      margin: 0 10px;
      height:2.7142em;
    }
  }
  table.dataTable{
    border:1px solid $light-semi-gray;
    thead{
      th,td{
        border-bottom:2px solid $light-semi-gray;
      }
    }
    th,td{
      padding: 0.75rem;
    }
  }
  .dataTables_filter{
    margin-bottom: 25px;
    margin-left: 15px;
    input[type="search"]{
      border: 1px solid $light-semi-gray;
      padding: 0 10px;
      margin-left: 10px;
      height: 37px;
      border-radius: 0;
    }
  }
}
.dataTables_scrollHeadInner{
  width: 100% !important;
}
table{
  .fixedHeader-floating {
    position: fixed!important;
    background-color: $white;
  }
  .box{
    >div{
      padding: 5px 15px;
      background-color: $primary-color;
      display: inline-block;
      color: $white;
      border-radius: 5px;
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
   &.dataTable{
     &.fixedHeader-locked,&.fixedHeader-floating{
       width:  calc(100vw - 250px)  !important;
       max-width: calc(100vw - 250px) !important;
       overflow: hidden !important;
       right: 0 !important;
       z-index: 99;
       left: 260px !important;
     }
      &:not(.fixedHeader-locked){
        &:not(.fixedHeader-floating){
          width: 100% !important;
        }
      }
   }
  &.dataTable{
    border-collapse: collapse!important;
    margin-top: 0 !important;
    thead{
      .sorting,.sorting_asc,.sorting_desc,.sorting_asc_disabled,.sorting_desc_disabled{
        &:before,&:after{
          bottom: 12px !important;
        }
      }
    }
    input,select{
      border: 1px solid $light-semi-gray;
      height: 37px;
    }
  }
}

.toolbar{
  padding: 5px 15px;
  background-color: $primary-color;
  display: inline-block;
  color: $white;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
code{
  &.option{
    color: $danger-color !important;
  }
  &.api{
    color: $success-color !important;
  }
}

// Datatable extension
.dt-ext{
  .dataTables_wrapper{
    .page-item{
      &.active {
        .page-link {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
      }
    }

    .dataTables_paginate{
      border: none;
      .paginate_button{
        padding: 0;
        border: none;
        &.disabled,&.hover,&.active{
            border: none !important;
        }
      }
      .page-link{
        margin-left: 0px;
        color: $primary-color;
      }
    }
    button.dt-button, div.dt-button, a.dt-button, button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled), a.dt-button:focus:not(.disabled), button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button.active:not(.disabled){
      background-color: $primary-color;
      border-color:$primary-color;
      border-radius: 2px;
      color: $white;
      background-image: none;
      font-size: 14px;
    }
    button.dt-button.btn-success, div.dt-button.btn-success, a.dt-button.btn-success{
      background-color: $success-color;
      border-color:$success-color;
    }
    button.dt-button.btn-primary, div.dt-button.btn-primary, a.dt-button.btn-primary{
      background-color: $primary-color;
      border-color:$primary-color;
    }
    button.dt-button.btn-danger, div.dt-button.btn-danger, a.dt-button.btn-danger{
      background-color: $danger-color;
      border-color:$danger-color
    }
    table.dataTable th.focus, table.dataTable td.focus{
      outline: 3px solid $primary-color;
    }
    table.dataTable.display tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1{
      background-color: lighten($primary-color , 20%);

    }
    table.dataTable.stripe tbody > tr.odd.selected, table.dataTable.stripe tbody > tr.odd > .selected, table.dataTable.display tbody > tr.odd.selected, table.dataTable.display tbody > tr.odd > .selected{
      background-color: lighten($primary-color , 20%);
    }
    table.dataTable.display tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1{
      background-color: lighten($primary-color , 20%);
    }
    table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected{
      background-color: lighten($primary-color , 20%);
    }
  }
}

td.highlight {
  background-color: $light-color !important;
}
td.details-control {
  background: url('../../images/details_open.png') no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url('../../images/details_close.png') no-repeat center center;
}
.dataTables_scroll{
  &~.dataTables_paginate{
    margin-top: 20px !important;
  }
}
.product-table{
  th{
    &:last-child{
      min-width: 120px;
    }
  }
  h6{
    font-weight: 600;
    color: $dark-color;
  }
}
table{
  &.dataTable{
    &.display {
      tbody {
        tr{
          &.odd {
            > .sorting_1{
              background-color: $light;
            }
          }
        }
      }
    }
  }
}
/**=====================
     30. Data-table CSS Ends
==========================**/
